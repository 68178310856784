import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../components/layout'
import "../styles/treatments.css"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { graphql } from 'gatsby'

export const data = graphql`
query{
    contentfulTreatments{
        content{
        json
        }
    }
    }
    `

const treatments = (probs) => {
    return (
        <Layout>
            <div className="treatments_background p_background_img">
            <div className="cover-img-overlay">

                <Container>
                    <Row>
                        <Col xl={12} lg={12} md={12} >
                        <div className="p_vertical_line tr"></div>
                            <h1 className="p_head_blog">Medical Tourism</h1>
                        </Col>
                    </Row>
                </Container>
            </div></div>
            {/* <div class="p_btm_svg"></div> */}

                <Container>
                    <p>
                        {documentToReactComponents(probs.data.contentfulTreatments.content.json)}
                    </p>
                </Container>
        </Layout>
    )
}

export default treatments